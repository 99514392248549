@import "../normalize.scss";
@import "../variables";

.footer {
    background-color: $footer-color;
    padding: 2rem 0 6.4rem 0;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        background: url(../../images/FooterPattern.svg);
        height: 22.3rem;
        width: 44.7rem;
        bottom: 0;
        right: 0;
    }

    &--information {
        padding-left: 12rem ;

        div {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4rem;

            img {
                margin-top: .5rem;
            }

            .pin {
                margin-top: .1rem;
            }

            .phone {
                margin-top: .3rem;
            }


            p {
                margin: 0;
                font-size: 2rem;
            }
        }
    }
}

.privacy {
    padding: 3rem;
    display: flex;
    justify-content: center;

    a {
        font-weight: bold;
        font-size: 1.8rem;
        color: #4F4F4F;
    }
}


@media screen and (max-width: 1024px) {
    .footer {

        &--information {
            padding-left: 8rem;
        }
    }
}


@media screen and (max-width: 800px) {
    .footer {

        &--information {
            padding-left: 2rem;
            margin-bottom: 21rem;
        }
    }
}