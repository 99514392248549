@import "../normalize.scss";
@import "../variables";



.cta {
    background: #71A39D;
    padding: 5.5rem;
    display: flex;
    justify-content: center;
    margin: 0 8.2rem;
    border-radius: .5rem;
    box-shadow: 0px 4px 10px #978A7B;
    position: relative;
    top: 15rem;
    z-index: 5;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        background: url(../../images/Cube.svg);
        z-index: 8;
        width: 14.1rem;
        height: 15.5rem;
        bottom: 2rem;
        right: 24rem;
    }

    &::before {
        content: "";
        position: absolute;
        background: url(../../images/Ripple.svg);
        z-index: -2;
        width: 35rem;
        height: 35rem;
        background-size: cover;
        background-repeat: no-repeat;
        top: -4rem;
        left: -3rem;
    }

    &--text {

        &--title {
            color: white;
            font-size: 5rem;
            font-weight: bold;
            margin: 0;
            margin-bottom: 5rem;
        }
    
        &--btn {
            color: white;
            background: $button-color;
            font-size: 3rem;
            padding: 2.4rem 6.6rem;
            text-decoration: none;
            border-radius: .5rem;
        }

        .center {
            display: flex;
            justify-content: center;
        }

    }
}

@media screen and (max-width: 1440px) {
    .cta {
        padding-top: 0;
    }
}

@media screen and (max-width: 1024px) {
    .cta {
        top: 9rem;

        &::before {
            left: -12rem;
            top: -12rem;
        }

        &::after {
            right: 5rem;
        }
        &--text {

            &--title {
                font-size: 3.5rem;
            }

            &--btn {
                font-size: 2.5rem;
            }

        }
    }
}

@media screen and (max-width: 700px) {
    .cta {
        margin: 0 2.2rem;
        padding: 3rem;

        &::before {
            width: 15rem;
            height: 15rem;
            top: -5rem;
            left: -5rem;
        }

        &::after {
            content: "";
        position: absolute;
        background: url(../../images/Ripple.svg);
        z-index: -2;
        height: 15rem;
        background-size: cover;
        background-repeat: no-repeat;
        bottom: -5rem;
        right: -5rem;
        }
        &--text {

            &--title {
                font-size: 1.6rem;
                margin-bottom: 2.8rem;
            }

            &--btn {
                font-size: 1.8rem;
            }
            
        }
    }
}