@import "../normalize.scss";
@import "../variables";

.architech {
    overflow: hidden;
    padding-top: 9.7rem;
    padding-bottom: 10rem;
    position: relative;
    margin-bottom: -12rem;

    .mobile {
        display: none;
    }

    .marquee {
        width: 100vw;
        z-index: -100;
        height: 20vh;
    }

    .marquee-content {
        display: flex;
        animation: scrolling 20s linear infinite;
        width: 200vw;
        z-index: -100;
        height: 12vw;

        img {
            z-index: -130;
        }
    }
    @keyframes scrolling {
        0% { transform: translateX(0);}
        100% { transform: translateX(-73.4%);}
    }

    .marquee2 {
        width: 100vw;
        height: 20rem;
        z-index: -1999;
    }

    .marquee-content2 {
        display: flex;
        animation: scrolling2 40s linear infinite;
        width: 200vw;
        z-index: -1000;
        height: 12vw;

        img {
            z-index: -130;
        }
    }

    @keyframes scrolling2 {
        0% { transform: translateX(0);}
        100% { transform: translateX(73.4%);}
    }

    @keyframes scrolling3 {
        0% { transform: translateX(0);}
        100% { transform: translateX(-201.5%);}
    }
    

    &--background-text {
        height: 100%;
        position: relative;
        bottom: -5vh;
        z-index: -300;
    }

    &--background-text2 {
        height: 100%;
        position: relative;
        bottom: 5vh;
        z-index: -3;
        left: -67.9%;
    }

    &--title {
        margin-bottom: 5rem;
        padding-left: 12rem;

        color: $heading-color;
        font-size: 6rem;
        font-weight: 700;
        &--bottom {
            margin-top: 1rem;
            margin-left: 4.3rem;
        }
    }

    &--grid {
        display: grid;
        grid-template-columns: repeat(12, 30.15vw);
        justify-self: center;
        column-gap: 3.4vw;
        position: relative;
        grid-template-rows: 1fr;
        grid-row: 1;
        z-index: 1000;

        animation: scrolling3 20s linear infinite;
    }

    &--image {
        width: 30.15vw;
        height: 30.15vw;
        border-radius: .3rem;
        box-shadow: 0px 3px 10px #A38866;
        z-index: 100;
        position: relative;
    }
}


@media screen and (max-width: 1024px) {
    .architech {
        &--background-text {
            bottom: -10vh;
        }

        &--background-text2 {
            bottom: 3vh;
        }

        &--title {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 478px) {
    .architech {
        padding-top: 0;

        .desktop {
            display: none;
        }

        .mobile {
            display:block;
        }

        &--title {
            font-size: 2rem;
            text-align: left;
            padding: 0;
        }

        .marquee2 {
            margin-bottom: -15rem;
        }


        &--background-text {
            bottom: -16vh;
        }

        &--background-text2 {
            bottom: 1vh;
        }

        &--title {
            text-align: center;
            margin: 0;
            margin-bottom: -6rem;

            &--bottom {
                margin: 0;
            }
        }

        
    }
}

@media screen and (min-width: 1500px) {
    .architech {

        &--title {
            padding-left: 0;
        }
    }
}