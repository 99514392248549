@import "../normalize.scss";
@import "../variables";


.contactbg {
    background: url(../../images/ContactBackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // filter: grayscale(50%);
    // padding: 0 0 23.2rem 6.6rem;
}


.contact {

    // background: url(../../images/ContactBackground.jpg);
    // position: relative;
    padding: 0 6.6rem 23.2rem 6.6rem;

    // &::before {
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    // }

    h2 {
        margin: 0;
        color: white;
        padding-top: 12rem;
        text-align: center;
        font-size: 4.5rem;
        margin-bottom: 9.5rem;
    }
    

    &--grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 11.3rem;

        &--image {
            // background: url(../../images/SDS.JPG);
            // width: auto;
            // height: 100%;
            background-size: cover;
            background-position: -30rem;
            // min-width: 70rem;
            // max-width: 70rem;

            img {
                height: 100%;
                width: 100%;
            }
        }
        // img {
        //     width: 100%;
        //     height: 90rem;
        //     object-fit: cover;
        //     object-position: -30rem;
        // }

        form {
            background: white;
            display: flex;
            flex-direction: column;
            padding: 6.4rem 6.7rem;
            border-radius: .5rem;
            max-width: 40rem;


            label {
                font-size: 2.2rem;
                font-weight: bold;
                margin-bottom: 2rem;
                margin-top: 4.4rem;
            }


            .margin-silence {
                margin-top: 0;
            }

            .text-field {
                height: 22rem;
                width: auto;
            }

            input {
                height: 7.7rem;
                border: 1px solid #818181;
                border-radius: 3px;
                width: auto;
                font-size: 2rem;
            }

            button {
                // display: flex;
                // position: relative;
                z-index: 8;
                width: auto;

                // img {
                //     position: absolute;
                //     height: 3rem;
                //     width: 3rem;
                //     z-index: 5000;
                // }

                font-size: 2.2rem;
                white-space: nowrap;
                color: white;
                background: $button-color;
                border: none;
                // justify-content: center;
                // align-items: center;
                padding: 2.5rem;
                border-radius: .5rem;
                margin-left: auto;
            }
        }
        p {
            font-size: 1.6rem;
            font-weight: bold;
            color: #696969;
            margin-bottom: 3rem;
        }
    }
}

@media screen and (max-width: 1024px) {

    .contact {
        &--grid {

            
            grid-template-columns: 100%;
            // grid-template-rows: 1fr 2fr;
            grid-auto-rows: auto;
            height: auto;
            &--image {
                            background: url(../../images/SDS.png);
            width: auto;
            height: 100%;
            background-size: cover;
            background-position: -30rem;
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                grid-row: 1;
                height: 40rem;
                background-position: 0rem;

                img {
                    display: none;
                }

            }
    
            &--form {
                grid-row: 2;
                // border-radius:  .5rem .5rem 0 0;
                
                

                form {
                    border-radius: 0;
                    border-bottom-left-radius: .5rem;
                border-bottom-right-radius: .5rem;
                max-width: 100%;

                }
            }

        }


    }

    // .contact {
    //     &--grid {
    //         grid-template-columns: 1fr;
    //         grid-template-rows: 1fr 5fr;
    //         height: auto;

    //         &--image {
    //             width: 100%;
    //             grid-row: 1;
    //             img {
    //                 height: 20rem;
    //                 width: 100%;
    //             }
    //         }

    //         &--form {
    //             grid-row: 2;
    //         }
    //     }
    // }
}

@media screen and (max-width: 600px) {

    .contact {

        p {
            font-size: 1.4rem;
        }
        padding: 6.6rem 2rem;
        display: flex;
        justify-content: center;
        &--grid {
            grid-template-columns: 1fr;
            // grid-template-rows: 1fr 2fr;
            grid-auto-rows: auto;
            height: auto;
            max-width: 100%;

            &--image {
                
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                grid-row: 1;
                height: 20rem;
                background-position: 0rem;
                max-width: 28rem;
                justify-self: center;
                min-width: 28rem;


            }

            &--form {
                grid-row: 2;
                // border-radius:  .5rem .5rem 0 0;
                max-width: 30rem;
                justify-self: center;
                

                form {
                    // width: ;
                    border-radius: 0;
                    border-bottom-left-radius: .5rem;
                    border-bottom-right-radius: .5rem;
                    padding: 6.6rem 2rem;
                    max-width: 24rem;


                    button {
                        margin: 0 auto;
                        padding: 2rem 6rem;
                    }
                    
                    
                }
        }
    }

}}


@media screen and (max-width: 340px) {
    .contact {
        padding: 6rem 0;
        margin: 0;
        box-sizing: border-box;

        &--grid {

            &--form {
                max-width: 30rem;
            }

            &--image {
                max-width: 24.4;
            }
        }
    }
}