@import "../normalize.scss";
@import "../variables";

.about {

    display: flex;
    background: url(../../images/ConstructionImageCropped.JPG);
    padding: 15rem 12rem 15rem 12rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -120rem;
    // background-position-x: -100rem;
    height: auto;
    // width: 150%;
    overflow-x: hidden;

    // &--background {
    //     position: absolute;
    //     // background: url(../../images/ConstructionImage.JPG);
    //     // background-position-y: -40rem;
    //     // background-position-x: 100%;
    //     // transform: translate(-50% -70%);
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     height: 100%;
    //     width: 100%;
    //     z-index: -999;
    //     overflow-y: hidden;
    //     overflow-x: hidden;

    //     img {
    //         position: relative;
    //         height:100%;
    //         width: 200%;
    //         object-position: -70rem;
    //     }
    // }
    .container {
        // padding: 15rem 12rem 15rem 12rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &--title {
        text-align: center;
        color: white;
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 7.8rem;
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        margin-top: 0;
    }
    &--card {
        background: #E7E7E7;
        // grid-template-columns: 2fr 1.4fr;
        // grid-template-rows: auto;
        height: auto;
        max-width: 60rem;


        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        // padding: 2rem;

        &--picture {
            // height: 100%;
            width: auto;
            display: none;
            // background-size: cover;
            height: auto;
            &--image {
                height: 100%;
                width: 50rem;
                // background: url(../../images/about-image.JPG);
                background-size: fill;
                background-repeat: no-repeat;
                border-radius: 0 .5rem .5rem 0;
            }
        }

    }


    
    

    &--information {
        padding: 3.8rem 6.4rem 3.8rem 4.7rem;

        h2 {
            font-size: 2.5rem;
            margin-top:0 ;
        }

        p {
            font-size: 1.7rem;
            line-height: 2.6rem;
            margin-top: 2.4rem;
        }
        .margin-silence {
            margin-top: 0;
        }
    }

}

// @media screen and (max-width: 1300px) {
//     .about {
//         padding: 0 8rem 15.3rem 8rem;

//         &--card {
//             height: 52rem;
//             box-sizing: border-box;
//             overflow: hidden;

//             &--picture {
//                 width: 40rem;
//                 background-size: cover;
//                 height: 52rem;
//             //     &--image {
//             //                 height: 100%;
//             //             width: 100%;
//             //                 background-position: -5rem;
//             //                 background-size: contain;
//             //             }
//             // }
//         }

      

//         &--information {
//             padding: 3.8rem 4.2rem 0 4.7rem;
//             p {
//                 font-size: 1.6rem;
//             }
//         }
//     } for mobile is 1020
// }


@media screen and (max-width: 1500px) {
    .about {

        background-position-x: 0;
        background-position-y: 0;
        background-size: cover;
        height: 100%;


        &--card {
            // grid-template-rows: 20rem 2fr;
            // grid-template-columns: auto;
            // height: auto;
            margin-bottom: 10.2rem;
            max-width: 60rem;
            // margin-right: 50%;
            // max-width: 50rem;
            // width: 100%;

            // &--picture {
            //     grid-row: 1;
            //     height: 20rem;
            //     // width: 100%;
            //     width: auto;
            //     background-size: cover;
            // &--image {
            //             height: 100%;
            //             width: 100%;
            //             border-radius: .5rem .5rem 0 0;
            //             // background-size: cover;
            //             background-position: 0rem -8rem;
            //             margin-bottom: 0;
            //         }
            // }
        }

        

        &--information {
            padding: 2rem 3.8rem 2.2rem 3.8rem;
            

            h2 {
                text-align: center;
            }
            p {
                text-align: center;
                font-size: 1.4rem;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .about {

        &--title {
            margin-top: 4rem;
        }

        background: none;
        &--mobile-background {
            background: url(../../images/ConstructionImageCropped.JPG);
            background-size: cover;
            height: 29rem;
            background-position-y: -14rem;
            background-repeat: no-repeat;
        }

        padding: 0 2rem 0rem;

        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

@media screen and (max-width: 428px) {
    .about {


        &--mobile-background {
            height: 29rem;
        }
    }
}

@media screen and (max-width: 375px) {
    .about {

        &--mobile-background {
            height: 25rem;
        }
    }
}

@media screen and (max-width: 360px) {
    .about {

        &--mobile-background {
            height: 24rem;
}
    }
}

@media screen and (max-width: 320px) {
    .about {

        &--mobile-background {
            height: 21rem;
}
    }
}